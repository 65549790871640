import React, { useState, useEffect, lazy } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import jwt_decode from "jwt-decode";

import Preloader from "components/shared/Preloader";
import CacheBuster from "./CacheBuster";
// import RouterComponent from "./Router";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { CartContextProvider } from "contexts/CartContext";
import { UserContextProvider } from "contexts/UserContext";
import { GamesContextProvider } from "contexts/GamesContext";
import { SidebarContextProvider } from "contexts/SidebarContext";
import { BundleCoursesContextProvider } from "contexts/BundleCoursesContext";
import { ActiveCoursesContextProvider } from "contexts/ActiveCoursesContext";

import { postRequest } from "utils/api";
import { isLoggedIn } from "utils/helper";

import "./Over-Write.css";
import "react-dropdown/style.css";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";

import SuspenseWrapper from "components/shared/SuspenseWrapper";
const RouterComponent = lazy(() => import("./Router"));

async function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  function refreshToken() {
    if (!isLoggedIn()) {
      setIsLoading(false);
      return;
    }

    let currEpoch = Math.floor(Date.now() / 1000);
    let expEpoch = parseInt(localStorage.getItem("exp"));

    if (expEpoch - currEpoch > 120) {
      setIsLoading(false);
      return;
    }

    let token = localStorage.getItem("authToken");
    postRequest("/auth/token", { token })
      .then((res) => {
        let decoded = jwt_decode(res.data.token);
        localStorage.setItem("authToken", res.data.token);
        localStorage.setItem("exp", decoded.exp);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  async function loadRazorpay() {
    const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
    if (!res) {
      toast.error("An error occured. Please reload");
      return;
    }
  }

  useEffect(() => {
    refreshToken();
    loadRazorpay();
  }, []);

  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }
        return isLoading ? (
          <Preloader />
        ) : (
          <UserContextProvider>
            <BundleCoursesContextProvider>
              <ActiveCoursesContextProvider>
                <SidebarContextProvider>
                  <CartContextProvider>
                    <GamesContextProvider>
                      <ToastContainer
                        position="top-right"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        rtl={false}
                        closeOnClick
                        pauseOnFocusLoss
                        pauseOnHover
                      />
                      <Router>
                        <SuspenseWrapper>
                          <RouterComponent />
                        </SuspenseWrapper>
                      </Router>
                    </GamesContextProvider>
                  </CartContextProvider>
                </SidebarContextProvider>
              </ActiveCoursesContextProvider>
            </BundleCoursesContextProvider>
          </UserContextProvider>
        );
      }}
    </CacheBuster>
  );
};

export default App;
