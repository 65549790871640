import React, { useReducer, useEffect, createContext, useContext } from "react";
import { getRequest } from "utils/api";
import { isLoggedIn } from "utils/helper";

export const UserContext = createContext();

const initialState = {
  profile: {
    courses: {
      active: [],
      completed: [],
      notStarted: [],
    },
  },
  userRole: "",
  isLoading: false,
  activeCourses: [],
  activeModule: null,
  isOpenLoginModal: false,
  loginRedirectRoute: null,
};

function reducer(state, action) {
  const { type, payload } = action;
  switch (type) {
    case "SET_USER_PROFILE":
      return { ...state, profile: payload };
    case "SET_USER_ROLE":
      return { ...state, userRole: payload };
    case "SET_ACTIVE_COURSES":
      return { ...state, activeCourses: payload };
    case "SET_ACTIVE_MODULE":
      return { ...state, activeModule: payload };
    case "SET_LOADING":
      return { ...state, isLoading: payload.isLoading };
    case "SET_LOGIN_MODAL":
      if (payload.loginRedirectRoute) {
        return {
          ...state,
          isOpenLoginModal: payload.isOpen,
          loginRedirectRoute: payload.loginRedirectRoute,
        };
      } else {
        return {
          ...state,
          isOpenLoginModal: payload.isOpen,
        };
      }
    default:
      return state;
  }
}

export function UserContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  function getCourseFromActiveCourses(activeCourseId) {
    const found = state.activeCourses.find((f) => f._id === activeCourseId);
    return found.courseDetails[0];
  }

  // function getUserCourses() {
  //   dispatch({ type: "SET_LOADING", payload: { isLoading: true } });
  //   getRequest("/user/courses")
  //     .then((resp) => {
  //       dispatch({ type: "SET_ACTIVE_COURSES", payload: resp.data });
  //     })
  //     .catch((err) => console.log(err))
  //     .finally(() => {
  //       dispatch({ type: "SET_LOADING", payload: { isLoading: false } });
  //     });
  // }

  function getUserData() {
    dispatch({ type: "SET_LOADING", payload: { isLoading: true } });
    getRequest("/user/profile")
      .then((resp) => {
        dispatch({ type: "SET_USER_PROFILE", payload: resp.data });
        dispatch({ type: "SET_USER_ROLE", payload: resp.data.role });

        if (resp.data.role !== "b2c_user") {
          getRequest("/user/courses")
            .then((resp) => {
              dispatch({ type: "SET_ACTIVE_COURSES", payload: resp.data });
            })
            .catch((err) => console.log(err))
            .finally(() => {
              dispatch({ type: "SET_LOADING", payload: { isLoading: false } });
            });
        } else {
          dispatch({ type: "SET_LOADING", payload: { isLoading: false } });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: "SET_LOADING", payload: { isLoading: false } });
      });
  }

  function refreshUserData() {
    getUserData();
  }

  function openLoginModal() {
    dispatch({ type: "SET_LOGIN_MODAL", payload: { isOpen: true } });
  }

  useEffect(() => {
    if (isLoggedIn()) {
      getUserData();
    }
  }, []);

  const value = {
    state,
    dispatch,
    getCourseFromActiveCourses,
    refreshUserData,
    openLoginModal,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export function useUser() {
  return useContext(UserContext);
}
